import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/order",
        name: "order",
        meta: { title: "订单" },
        redirect: "/order/renew/lists",
        component: Main,
        children: [
            {
                path: "/order/renew/lists",
                name: "renew_order",
                meta: {
                    title: "续费订单",
                    icon: "icon_fenxiao_order",
                    parentPath: "/order",
                    permission: ["view"],
                },
                component: () => import("@/views/order/renew/lists.vue"),
            },
            {
                path: "/order/renew/detail",
                name: "renew_order_detail",
                meta: {
                    hidden: true,
                    title: "续费订单",
                    parentPath: "/order",
                    prevPath: "/order/renew/lists"
                },
                component: () => import("@/views/order/renew/detail.vue"),
            },
        ],
    },
];
export default routes;
