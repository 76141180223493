import Main from '@/layout/main.vue'
import Blank from "@/layout/blank.vue";

const routes = [{
    path: '/',
    name: 'index',
    meta: {title: '工作台'},
    redirect: '/index',
    component: Main,
    children: [
        {
            path: '/index',
            name: 'index',
            meta: {
                hidden: true,
                title: '工作台',
                parentPath: '/',
                permission: ['view']
            },
            component: () => import('@/views/index/home.vue'),
        },
        {
            path: '/setting/platform/config',
            name: 'platform',
            meta: {
                title: '平台设置',
                parentPath: '/',
                icon: 'icon_set_store'
            },
            component: Blank,
            redirect: '/setting/platform/config',
            children: [{
                path: '/setting/platform/config',
                name: 'config',
                meta: {
                    title: '平台信息',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/platform/config.vue')
            }, {
                path: '/setting/platform/keep_on_record',
                name: 'keep_on_record',
                meta: {
                    title: '备案信息',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/platform/keep_on_record.vue')
            }]
        },
        {
            path: '/setting/payment/config',
            name: 'setting_pay_config',
            meta: {
                title: '支付配置',
                icon: 'icon_set_pay',
                parentPath: '/',
                permission: ['view']
            },
            component: () => import('@/views/setting/payment/pay_config.vue')
        },
        {
            path: '/setting/payment/pay_config_edit',
            name: 'setting_pay_config_edit',
            meta: {
                hidden: true,
                title: '支付方式设置',
                parentPath: '/',
                prevPath: '/setting/payment/config'
            },
            component: () => import('@/views/setting/payment/pay_config_edit.vue')
        },
        {
            path: '/setting/service/service',
            name: 'service',
            meta: {
                title: '客服设置',
                parentPath: '/',
                icon: 'icon_notice_buyer'
            },
            component: Blank,
            redirect: '/setting/service/service',
            children: [{
                path: '/setting/service/service',
                name: 'config',
                meta: {
                    title: '联系客服',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/service/service.vue')
            }]
        }, {
            path: '/setting/security/account',
            name: 'security',
            meta: {
                title: '安全设置',
                parentPath: '/',
                icon: 'icon_set_quanxian'
            },
            component: Blank,
            redirect: '/setting/security/account',
            children: [{
                path: '/setting/security/account',
                name: 'journal',
                meta: {
                    title: '修改密码',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/security/account.vue')
            }]
        }, {
            path: '/setting/system_maintain/journal',
            name: 'system_maintain',
            meta: {
                title: '系统维护',
                parentPath: '/',
                icon: 'icon_set_weihu'
            },
            component: Blank,
            redirect: '/setting/system_maintain/journal',
            children: [{
                path: '/setting/system_maintain/journal',
                name: 'journal',
                meta: {
                    title: '系统日志',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/journal.vue')
            }, {
                path: '/setting/system_maintain/decorate',
                name: 'decorate',
                meta: {
                    title: '装修重置',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/decorate.vue')
            }, {
                path: '/setting/system_maintain/cache',
                name: 'cache',
                meta: {
                    title: '系统缓存',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/cache.vue')
            }, {
                path: '/setting/system_maintain/updata',
                name: 'updata',
                meta: {
                    title: '系统升级',
                    // hidden: true,
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/updata.vue')
            }, {
                path: '/setting/system_maintain/environment',
                name: 'environment',
                meta: {
                    title: '系统环境',
                    // hidden: true,
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/environment.vue')
            }, {
                path: '/setting/system_maintain/task',
                name: 'task',
                meta: {
                    title: '定时任务',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/task/lists.vue')
            }, {
                path: '/setting/system_maintain/task_edit',
                name: 'task_edit',
                meta: {
                    hidden: true,
                    title: '定时任务',
                    parentPath: '/setting',
                    permission: ['view']
                },
                component: () => import('@/views/setting/system_maintain/task/edit.vue')
            }]
        }, {
            path: '/setting/storage/storage',
            name: 'order',
            meta: {
                title: '存储设置',
                parentPath: '/',
                icon: 'icon_set_save'
            },
            component: Blank,
            redirect: '/setting/storage/storage',
            children: [{
                path: '/setting/storage/storage',
                name: 'setting_storage',
                meta: {
                    title: '设置A',
                    parentPath: '/setting',
                    hidden: true,
                },
                component: () => import('@/views/setting/storage/storage.vue')
            }, {
                path: '/setting/storage/index',
                name: 'setting_storage_index',
                meta: {
                    title: '存储设置',
                    parentPath: '/',
                    permission: ['view']

                },
                component: () => import('@/views/setting/storage/index.vue')
            }]
        },
    ],
}]

export default routes